import React from 'react'
import { Flex, Text } from '@chakra-ui/react'



const AboutUs = ({ advantages }) => {
  return (
    <Flex zIndex={90} w={"full"} justifyContent={"center"} p={"50px 10px"} id='aboutus'>
      <Flex display={["none", "none", "none", "flex"]} maxW={"1500px"} w={"full"} boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"} bg={"white"} justifyContent={"space-between"} p={"20px"}  >

        <Flex gap={4} flexDir={"column"} w={"700px"} >
          <Text mb={"15px"} fontFamily={"Roboto"} fontWeight={700} fontSize={"24px"} borderBottom={"2px solid #FE9901"} w={"300px"}> Компания Престиж Строй</Text>
          <Text fontSize={"16px"}> Предоставляет основные виды работ по благоустройству:</Text>
          <Flex bg={"white"} gap={3} flexDir={"column"} fontSize={"15px"} >
            <Text>
              1. Составление плана и разработка территории с использованием специальной техники.

            </Text>
            <Text>
              2. Удаление старых деревьев, корчевание пней, сбор и вывоз бытовых отходов и строительного мусора.

            </Text>
            <Text>
              3. Асфальтирование или мощение дорожек, тротуаров, площадок.

            </Text>
            <Text>
              4. Укладка тротуарной плитки

            </Text>
            <Text>
              5. Установка поребрик, бортовых камней

            </Text>
            <Text>
              6. Обустройство мест для свободного времяпрепровождения (отдыха).

            </Text>
            <Text>
              7. Возведение площадок для детей и занятий спортом.

            </Text>
            <Text>
              8. Монтаж коммуникационных подводок для работы осветительных и поливных систем.

            </Text>
            <Text>
              9. Установка малых архитектурных форм (беседок, скамеек, скульптурных композиций).

            </Text>
            <Text>
              10. Размещение на территории ливневых и дренажных систем.

            </Text>
            <Text>
              11. Формирование искусственных водоемов (прудов, фонтанов).

            </Text>
            <Text>
              12. Расположение приборов освещения и ограждений.

            </Text>
            <Text>
              13. Озеленительные работы: посадка саженцев деревьев и кустарников, плетущихся вертикальных насаждений, разбивка газонов и клумб.

            </Text>
            <Text>
              14. Подготовка дорожного участка, включающая очистку от мусора и растительности, а также удаление поврежденного покрытия.

            </Text>
            <Text>
              15. Укладка мягкой кровли, а также нового асфальтового покрытия или других материалов, таких как бетон или камень

            </Text>
            <Text>
              А также предоставляет услуги по доставке/перевозке: ЩПС, ГПС, песок, асфальтной крошке и прочие сыпучие материалы по г. Томску и Томской области</Text>

          </Flex>
        </Flex>
        <Flex w={"700px"} alignItems={"center"} flexDir={"column"} gap={10} display={["none", "none", "none", "flex"]}>
          <Text fontSize={"18px"} fontWeight={700} >Приемущество нашей компании</Text>
          <Flex flexWrap={"wrap"} gap={10} alignItems={"center"} justifyContent={"center"}>
            {
              advantages?.map(({ id, icon, title, about }) => (
                <Flex key={id} w={"200px"} flexDir={"column"} alignItems={"center"} gap={1}>
                  {icon}
                  <Text fontWeight={700} color={"colors.brand.100"} fontSize={"16px"} textAlign={"center"}>{title}</Text>
                  <Text textAlign={"center"} fontSize={"13px"}>{about}</Text>
                </Flex>
              ))
            }
            <Flex  gap={2}>
              <Flex w={"160px"} flexDir={"column"} alignItems={"center"} gap={1}>

                <Text fontWeight={700} color={"colors.brand.100"} fontSize={"26px"} textAlign={"center"}>2874</Text>
                <Text textAlign={"center"} fontSize={"13px"}>Довольних клиентов</Text>
              </Flex>
              <Flex w={"160px"} flexDir={"column"} alignItems={"center"} gap={1}>
                <Text fontWeight={700} color={"colors.brand.100"} fontSize={"26px"} textAlign={"center"}>20</Text>
                <Text textAlign={"center"} fontSize={"13px"}>Лет на рынке</Text>
              </Flex>

              <Flex w={"160px"} flexDir={"column"} alignItems={"center"} gap={1}>

                <Text fontWeight={700} color={"colors.brand.100"} fontSize={"26px"} textAlign={"center"}>85%</Text>
                <Text textAlign={"center"} fontSize={"13px"}>клиентов приходят по рекомендациям</Text>
              </Flex>
              <Flex w={"160px"} flexDir={"column"} alignItems={"center"} gap={1}>
                <Text fontWeight={700} color={"colors.brand.100"} fontSize={"26px"} textAlign={"center"}>7</Text>
                <Text textAlign={"center"} fontSize={"13px"}>Наши рабочие дни в течении недели</Text>
              </Flex>
            </Flex>
          </Flex>

        </Flex>
      </Flex>
      <Flex boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"} bg={"white"} display={["flex", "flex", "flex", "none"]} m={'7px'} p={"10px"} >
        <Flex gap={2} flexDir={"column"} maxW={"700px"} w={"full"}>
          <Text mb={"15px"} fontFamily={"Roboto"} fontWeight={700} fontSize={"20px"} textDecor={"underline"} textDecorationColor={"#FE9901"} textUnderlineOffset={"15px"} flexWrap={"nowrap"}> Компания Престиж Строй</Text>
          <Text fontSize={"15px"}> Предоставляет основные виды работ по благоустройству:</Text>
          <Flex bg={"white"} gap={2} flexDir={"column"} fontSize={"14px"} >
            <Text>
              1. Составление плана и разработка территории с использованием специальной техники.

            </Text>
            <Text>
              2. Удаление старых деревьев, корчевание пней, сбор и вывоз бытовых отходов и строительного мусора.

            </Text>
            <Text>
              3. Асфальтирование или мощение дорожек, тротуаров, площадок.

            </Text>
            <Text>
              4. Укладка тротуарной плитки

            </Text>
            <Text>
              5. Установка поребрик, бортовых камней

            </Text>
            <Text>
              6. Обустройство мест для свободного времяпрепровождения (отдыха).

            </Text>
            <Text>
              7. Возведение площадок для детей и занятий спортом.

            </Text>
            <Text>
              8. Монтаж коммуникационных подводок для работы осветительных и поливных систем.

            </Text>
            <Text>
              9. Установка малых архитектурных форм (беседок, скамеек, скульптурных композиций).

            </Text>
            <Text>
              10. Размещение на территории ливневых и дренажных систем.

            </Text>
            <Text>
              11. Формирование искусственных водоемов (прудов, фонтанов).

            </Text>
            <Text>
              12. Расположение приборов освещения и ограждений.

            </Text>
            <Text>
              13. Озеленительные работы: посадка саженцев деревьев и кустарников, плетущихся вертикальных насаждений, разбивка газонов и клумб.

            </Text>
            <Text>
              14. Подготовка дорожного участка, включающая очистку от мусора и растительности, а также удаление поврежденного покрытия.

            </Text>
            <Text>
              15. Укладка мягкой кровли, а также нового асфальтового покрытия или других материалов, таких как бетон или камень

            </Text>
            <Text>
              А также предоставляет услуги по доставке/перевозке: ЩПС, ГПС, песок, асфальтной крошке и прочие сыпучие материалы по г. Томску и Томской области</Text>

          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AboutUs