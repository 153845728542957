import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import WorkImageMain from '../../assets/img/workImageMain.jpeg'
import WorkImage1 from '../../assets/img/workImg1.jpg'
import WorkImage3 from '../../assets/img/workImg3.jpeg'
import WorkImage4 from '../../assets/img/workImg4.jpeg'
import WorkImage6 from '../../assets/img/workImg6.jpeg'
import WorkImage7 from '../../assets/img/workImg7.jpeg'
import WorkImage8 from '../../assets/img/workImg8.jpeg'
import WorkImage9 from '../../assets/img/workImg9.jpeg'
import WorkImage10 from '../../assets/img/workImg10.jpeg'
import WorkImage11 from '../../assets/img/workImg11.jpeg'
import WorkImage12 from '../../assets/img/workImg12.jpeg'
import WorkImage13 from '../../assets/img/workImg13.jpeg'
import WorkImage14 from '../../assets/img/workImg14.jpeg'
import WorkImage15 from '../../assets/img/workImg15.jpeg'
import WorkImage16 from '../../assets/img/workImg16.jpeg'
import WorkImage17 from '../../assets/img/workImg17.jpeg'
import WorkImage18 from '../../assets/img/workImg18.jpeg'
import WorkImage19 from '../../assets/img/workImg19.jpeg'
import WorkImage20 from '../../assets/img/workImg20.jpeg'
import WorkImage21 from '../../assets/img/workImg21.jpeg'
import WorkImage22 from '../../assets/img/workImg22.jpeg'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './style.css';

// import required modules
import { Pagination } from 'swiper/modules';
import { Image } from '@chakra-ui/react';

export default function CustomSwiper() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        pagination={{
          clickable: true,
          
        }}
        modules={[Pagination]}
        breakpoints={{
         
          642:{
            slidesPerView:2
          },
          1000:{
            slidesPerView:3
          },
        }}
        className="mySwiper"
      >
        <SwiperSlide >
          <Image src={WorkImageMain} />
        </SwiperSlide>
        <SwiperSlide>
        <Image src={WorkImage18} />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={WorkImage9} />
        </SwiperSlide>
      
        <SwiperSlide>
          <Image src={WorkImage1} />
        </SwiperSlide>
        <SwiperSlide>
        <Image src={WorkImage21} />

        </SwiperSlide>
        <SwiperSlide>
          <Image src={WorkImage22} />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={WorkImage17} />
        </SwiperSlide>
        <SwiperSlide>
        <Image src={WorkImage14} />

        </SwiperSlide>
        <SwiperSlide>
          <Image src={WorkImage13} />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={WorkImage3} />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={WorkImage4} />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={WorkImage6} />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={WorkImage7} />
        </SwiperSlide>    
         <SwiperSlide>
          <Image src={WorkImage15} />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={WorkImage10} />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={WorkImage11} />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={WorkImage12} />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={WorkImage16} />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={WorkImage8} />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={WorkImage19} />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={WorkImage20} />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

