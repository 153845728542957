import React from "react";
import { Flex, Text } from "@chakra-ui/react";


function AdvantagesMobile({ advantages }) {
  return (
    <Flex w={"full"} justifyContent={"center"}  p={"0 10px"}>
      <Flex maxW={"700px"} display={['flex', 'flex', 'flex', 'none']} w={"full"} alignItems={"center"} flexDir={"column"} gap={10} mt={"30px"} bg={"white"} boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"} p={"10px 15px"}>
        <Text fontSize={"18px"} fontWeight={700} textDecor={"underline"} textDecorationColor={"#FE9901"} textUnderlineOffset={"8px"}>Приемущество нашей компании</Text>
        <Flex maxW={"700px"} w={"full"} flexWrap={"wrap"} gap={10} alignItems={"center"} justifyContent={"space-between"}>
          {
            advantages?.map(({ id, icon, title, about, display }) => (
              <Flex key={id} w={"130px"} flexDir={"column"} alignItems={"center"} gap={1} display={display} >
                {icon}
                <Text fontWeight={700} color={"colors.brand.100"} fontSize={"14px"} textAlign={"center"}>{title}</Text>
                <Text textAlign={"center"} fontSize={"13px"}>{about}</Text>
              </Flex>

            ))
          }
          <Flex w={"full"} justifyContent={"space-between"} flexWrap={"wrap"} gap={2} alignItems={"center"}>
            <Flex w={"140px"} flexDir={"column"} alignItems={"center"} gap={1}>

              <Text fontWeight={700} color={"colors.brand.100"} fontSize={"26px"} textAlign={"center"}>2874</Text>
              <Text textAlign={"center"} fontSize={"13px"}>Довольних клиентов</Text>
            </Flex>
            <Flex w={"140px"} flexDir={"column"} alignItems={"center"} gap={1}>
              <Text fontWeight={700} color={"colors.brand.100"} fontSize={"26px"} textAlign={"center"}>20</Text>
              <Text textAlign={"center"} fontSize={"13px"}>Лет на рынке</Text>
            </Flex>

            <Flex w={"140px"} flexDir={"column"} alignItems={"center"} gap={1}>

              <Text fontWeight={700} color={"colors.brand.100"} fontSize={"26px"} textAlign={"center"}>85%</Text>
              <Text textAlign={"center"} fontSize={"13px"}>клиентов приходят по рекомендациям</Text>
            </Flex>
            <Flex w={"140px"} flexDir={"column"} alignItems={"center"} gap={1}>
              <Text fontWeight={700} color={"colors.brand.100"} fontSize={"26px"} textAlign={"center"}>7</Text>
              <Text textAlign={"center"} fontSize={"13px"}>Наши рабочие дни в течении недели</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}


export default AdvantagesMobile