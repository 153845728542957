import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import CustomSwiper from "../../../components/Swiper/Swiper";
import './style.css'


function OurWork() {
    return (
        <Flex w={"full"} className="works" justifyContent={"center"} p={"50px 10px"} alignItems={"center"} id="work">
            <Flex maxW={"1500px"} w={"full"} flexDir={"column"} gap={8}>
                <Flex flexDir={"column"} gap={2}>
                    <Text fontSize={"30px"} fontWeight={700}  textDecor={"underline"} textDecorationColor={"#FE9901"} textUnderlineOffset={"8px"} fontFamily={"Montserrat"} flexWrap={"nowrap"}>Наши работы</Text>
                    <Text letterSpacing={"2px"} fontSize={"16px"}>Примеры наших проектов</Text>
                </Flex>
                <CustomSwiper />
            </Flex>
        </Flex>
    )
}


export default OurWork