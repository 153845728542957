import React, { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/routes";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./themes/theme";
import SEO from "./components/seo";

function App() {
  return (
    <Suspense fallback="loading">
      <ChakraProvider theme={theme}>
        {/* <SEO /> */}
        <RouterProvider router={router} />
      </ChakraProvider>
    </Suspense>
  );
}

export default App;
