import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'
import { ReactComponent as Phone } from '../../assets/svg/phone.svg'
import { ReactComponent as Telegram } from '../../assets/svg/telegram.svg'
import { ReactComponent as Whatsapp } from '../../assets/svg/whatsapp.svg'

// import { HashLink as Link } from 'react-router-hash-link'
import { Link as ScrollLink } from 'react-scroll'
import { Link } from 'react-router-dom'
import {
  Button,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react'
import CustomDrawer from '../Drawer/Drawer'
import Logo from '../../assets/img/logoWithoutText.png'
import './style.css'
const Header = ({ backBtn, title }) => {


  return (
    <Flex pos={"fixed"} zIndex={"999"} w={"full"} bg={"rgba(26, 33, 52, 0.5)"} h={"80px"} justifyContent={"center"} fontFamily={"Montserrat"}>
      <Flex maxW={"1500px"} w={"full"} justifyContent={'space-between'} alignItems={"center"} display={["none", "none", "none", "flex"]} className='scrolled' id='header'>
        <Flex gap={5} color={"white"} zIndex={"100"} className='main' alignItems={"center"} p={"0 15px"}>
          <ScrollLink
            to={"home"}
            smooth={true}
            duration={500}
            offset={-25}
            className='navLinkBtns'

          >
            <Image src={Logo} w={"50px"} h={"50px"} />
          </ScrollLink>
          <ScrollLink to='aboutus'
            className='navLinkBtns'
            smooth={true}
            duration={500}
            offset={-25}>О нас</ScrollLink>
          <ScrollLink to={"work"}
            className='navLinkBtns'
            smooth={true}
            duration={500}
            offset={-25}>Работы</ScrollLink>
          <ScrollLink to={"specialequipment"}
            className='navLinkBtns'
            smooth={true}
            offset={50}
            duration={500}>
            Спецтехника
          </ScrollLink>
          <ScrollLink to={"services"}
            className='navLinkBtns'
            smooth={true}
            duration={500}
            >Наши услуги</ScrollLink>
          <ScrollLink to={"collectiv"}
            className='navLinkBtns'
            smooth={true}
            duration={500}
            offset={50}

            >Коллектив</ScrollLink>
        </Flex>
        <Flex maxW={"500px"} w={"full"} bg={"colors.brand.100"} h={"80px"} alignItems={"center"} p={"0 15px"} justifyContent={'space-between'}>
          <Flex alignItems={"center"} gap={3}>
            <Button variant={"rounded"} cursor={"default"}>
              <Phone />
            </Button>
            <Flex flexDir={"column"}>
              <Link to={"tel:79528824439"} className='forCall'>+7 952 882-44-39</Link>
              <Link to={"tel:79138548580"} className='smallLink' >+7 913 854-85-80 </Link>
            </Flex>
          </Flex>
          <Flex gap={1} alignItems={"center"}>
            <Link className='navLink' to='https://t.me/+79528824439' target='_blank'>
              <Telegram />
            </Link>
            
            <Link className='navLink' to='https://wa.me/+79528824439' target='_blank'>
              <Whatsapp />
            </Link>
          </Flex>
        </Flex>
      </Flex>
      <Flex w={"full"} display={["flex", "flex", "flex", "none"]} alignItems={"start"} justifyContent={"start"}>
        <Flex maxW={"958px"} w={"full"} bg={"colors.brand.100"} h={"80px"} alignItems={"center"} p={"0 15px"} justifyContent={'space-between'}>
          <Flex alignItems={"center"} gap={3}>
            <Button variant={"rounded"} cursor={"default"}>
              <Phone />
            </Button>
            <Flex flexDir={"column"}>
              <Text fontWeight={600} fontSize={"16px"}>+7 952 882-44-39</Text>
              <Text fontWeight={600} fontSize={"14px"} display={"flex"} alignItems={"center"} gap={2}>+7 913 854-85-80 </Text>
            </Flex>
          </Flex>
          <Flex gap={4}>
            <Link className='navLink' to='https://t.me/+79528824439' target='_blank'>
              <Telegram />
            </Link>
            <Link className='navLink' to='https://wa.me/79528824439' target='_blank' >
              <Whatsapp />
            </Link>
          </Flex>
        </Flex>
        <Flex maxW={"70px"} w={"full"} bg={"colors.brand.100"} h={"80px"} alignItems={"center"} justifyContent={"center"}>
          <CustomDrawer />
        </Flex>
      </Flex>

    </Flex>
  )
}

export default Header