import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";


function OurServices({ services }) {
    return (
        <Flex w={"full"} justifyContent={"center"} p={"50px 10px"} id="services">
            <Flex maxW={"1500px"} w={"full"} flexDir={"column"} color={"black"} gap={8} >
                <Flex w={"full"} flexDir={"column"} gap={2} >
                    <Text fontSize={"30px"} fontWeight={700} flexWrap={"nowrap"} fontFamily={"Montserrat"} textDecor={"underline"} textDecorationColor={"#FE9901"} textUnderlineOffset={"9px"}>Наши услуги</Text>
                    <Text fontSize={"16px"} letterSpacing={"2px"}>Перечень наших услуг</Text>
                </Flex>
                <Flex w={"full"} flexWrap={"wrap"} justifyContent={"space-between"} gap={"80px 0"} alignItems={"center"}>
                    {
                        services?.map(({ id, image, title }) => (
                            <Flex key={id} flexDir={"column"} pos={"relative"} alignItems={"center"}>
                                <Image w={"450px"} h={"280px"} src={image} borderRadius={"20px"} objectFit={"cover"} />
                                <Flex maxW={"340px"} w={"full"} pos={"absolute"} zIndex={"55"} bottom={"-60px"} alignItems={"center"} gap={5} >
                                    <Text
                                        fontSize={"24px"}
                                        fontWeight={900}
                                        boxShadow={"0 0 10px 1px rgba(176, 176, 176, 0.5)"}
                                        bg={"colors.brand.100"}
                                        color={"white"}
                                        borderRadius={"15px"}
                                        p={"19px 19px"}>{id}</Text>
                                    <Text fontWeight={700} fontSize={"15px"}>{title}</Text>
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>
        </Flex>
    )
}


export default OurServices