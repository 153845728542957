import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import SpecialEquipment from "../../../assets/img/specialEquipment.jpeg"



function Equipment() {
    return (
        <Flex w={"full"} pt={"50px"} id="specialequipment">
            <Flex w={"full"} pos={"relative"} justifyContent={"center"} display={["none", "none", "none", "flex"]}>
                <Image src={SpecialEquipment} w={"full"} pos={"relative"} maxH={"1050px"} h={"full"} />
                <Flex w={"full"} zIndex={50} bg={"rgba(0, 0, 0, 0.4)"} maxH={"1050px"} h={"full"} p={"190px"} pos={"absolute"} justifyContent={"center"}>
                    <Flex maxW={"1500px"} w={"full"}  >
                        <Flex flexDir={"column"} gap={2} color={"white"}>
                            <Flex flexDir={"column"} gap={"240px"} justifyContent={"space-between"} pos={"relative"} zIndex={"60"}>
                                <Text fontSize={"59px"} fontWeight={700} textDecor={"underline"} textDecorationColor={"#FE9901"} textUnderlineOffset={"15px"} fontFamily={"Montserrat"} >Спецтехника для <br /> благоустройства  территории</Text>
                                <Text fontSize={"23px"} w={"901px"}>Мы имеем огромный парк строительной и дорожной техники. В отличном состоянии катки различных модификаций и трамбовочные агрегаты, экскаваторы погрузчики, автомобильные краны и тяжелая спецтехника.</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex display={["flex", "flex", "flex", "none"]}>
            <Image src={SpecialEquipment} w={"full"} pos={"relative"} h={"800px"} objectFit={"cover"} />
                <Flex w={"full"} zIndex={50} bg={"rgba(0, 0, 0, 0.4)"} h={"800px"} pos={"absolute"} justifyContent={"center"}>
                    <Flex maxW={"1500px"} w={"full"} p={"150px 0"}  >
                        <Flex flexDir={"column"} gap={2} color={"white"}>
                            <Flex flexDir={"column"} gap={"240px"} p={"15px 10px"} justifyContent={"space-between"} pos={"relative"} zIndex={"60"}>
                                <Text fontSize={"20px"} fontWeight={700} flexWrap={"nowrap"} textDecor={"underline"} textDecorationColor={"#FE9901"} textUnderlineOffset={"10px"} fontFamily={"Montserrat"} >Спецтехника для <br /> благоустройства  территории</Text>
                                <Text fontSize={"15px"} >Мы имеем огромный парк строительной и дорожной техники. В отличном состоянии катки различных модификаций и трамбовочные агрегаты, экскаваторы погрузчики, автомобильные краны и тяжелая спецтехника.</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}


export default Equipment