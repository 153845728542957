import { Flex } from '@chakra-ui/react'
import React from 'react'
import AboutUs from './AboutUs'
import OurWork from './Work'
import OurServices from './OurServices'
import { ReactComponent as Payment } from '../../assets/svg/payment.svg'
import { ReactComponent as Professional } from '../../assets/svg/professional.svg'
import { ReactComponent as Roller } from '../../assets/svg/roller.svg'
import { ReactComponent as Factory } from '../../assets/svg/factory.svg'
import { ReactComponent as Document } from '../../assets/svg/documentation.svg'
import { ReactComponent as Control } from '../../assets/svg/camera.svg'
import { ReactComponent as Delivery } from '../../assets/svg/delivery.svg'
import { ReactComponent as Tools } from '../../assets/svg/tools.svg'
import { ReactComponent as Bulldozer } from '../../assets/svg/bulldozer.svg'
import Usluga1 from '../../assets/img/blago.jpg'
import Usluga2 from '../../assets/img/plitka.jpg'
import Usluga3 from '../../assets/img/landshaft.jpg'
import Usluga4 from '../../assets/img/asfalt.jpg'
import Usluga5 from '../../assets/img/zemlya.jpg'
import Usluga6 from '../../assets/img/montage.jpg'
import Usluga7 from '../../assets/img/grunt.jpg'
import Usluga8 from '../../assets/img/musr.jpg'
import Usluga9 from '../../assets/img/krovlya.jpeg'
import Equipment from './Equipment'
import Content from './Content'
import Collectiv from './Collectiv'
import AdvantagesMobile from './AdvantagesMobile'



const Home = () => {

  const Advantages = [
    { id: 1, icon: <Payment />, title: "БЕЗ ПРЕДОПЛАТЫ", about: "Вы будете оплачивать лишь принятые вами работы, качество которых вас устраивает", display:"flex" },
    { id: 2, icon: <Professional />, title: "РАБОТАЮТ ПРОФЕССИОНАЛЫ", about: "Команда профессиональных строителей, которые прошли аттестацию", display:"flex" },
    { id: 3, icon: <Document />, title: "ДОКУМЕНТАЦИЯ", about: "Полный пакет проектной документации, который вы получаете бесплатно", display:"flex" },
    { id: 4, icon: <Control />, title: "КОНТРОЛЬ КАЧЕСТВА", about: "Можно отслеживать ход проведения работ в режиме реального времени", display:"flex" },
    { id: 5, icon: <Roller />, title: "КАЧЕСТВЕННЫЕ МАТЕРИАЛЫ", about: "Мы сами купим и доставим самые высококачественные материалы", display:"flex" },
    { id: 6, icon: <Factory />, title: "ГАРАНТИЯ", about: "Мы проявляем индивидуальный подход и заботу для каждого нашего клиента", display:"flex" },
    { id: 7, icon: <Bulldozer />, title: "СПЕЦТЕХНИКА", about: "Мы имеем огромный парк строительной и дорожной техники.", display:"none" },
    { id: 8, icon: <Delivery />, title: "БЫСТРЫЙ ВЫЕЗД", about: "Быстро приближаемся к Томску и соседним городам.", display:"flex" },
    { id: 9, icon: <Tools />, title: "БЕСПЛАТНЫЕ ЗАМЕРЫ", about: "Измеряем новыми приборами и точно.", display:"flex" },
  ]


  const Services = [
    { id: 1, image: Usluga1, title: "Благоустройство территорий" },
    { id: 2, image: Usluga2, title: "Укладка плитки и бордюра" },
    { id: 3, image: Usluga3, title: "Ландшафтный дизайн" },
    { id: 4, image: Usluga4, title: "Асфальтирование территорий" },
    { id: 5, image: Usluga5, title: "Земляные работы" },
    { id: 6, image: Usluga6, title: "Строительно монтажные работы" },
    { id: 7, image: Usluga7, title: "Вывоз грунта" },
    { id: 8, image: Usluga8, title: "Вывоз строительного мусора" },
    { id: 9, image: Usluga9, title: "Мягкой Кровли" },
  ]

  return (
    <Flex id='home' w={"full"} bg={"colors.brand.200"} justifyContent={"center"} flexDirection={"column"} fontFamily={"Montserrat"}>
     <Content />
      <AboutUs  advantages={Advantages} />
       <OurWork />
       <AdvantagesMobile advantages={Advantages} />
      <Equipment />
      <OurServices services={Services} />
      <Collectiv />
    </Flex>
  )
}

export default Home