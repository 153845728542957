import React from "react";
import { Button, Flex, FormLabel, Image, Text } from "@chakra-ui/react";
import { ReactComponent as Telegram } from '../../assets/svg/telegram.svg'
import { ReactComponent as Whatsapp } from '../../assets/svg/whatsapp.svg'
import Logo from '../../assets/img/stroykaLogo.png'
import { Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll'

import './style.css'

function Footer() {
    return (
        <Flex w={"full"} justifyContent={"center"} bg={"#1A2134"} color={"white"} fontFamily={"Montserrat"} p={"20px 0"} >
            <Flex alignItems={"center"} maxW={"1500px"} w={"full"} justifyContent={"space-between"} display={["none", "none", "none", "flex"]}>
                <Flex>
                    <Image src={Logo} w={"300px"} h={"300px"} />
                </Flex>
                <Flex gap={10}>
                    <Flex gap={"80px"}>
                        <Flex flexDir={"column"} gap={3} fontWeight={400}  >
                            <Text fontSize={"16px"}>Компания</Text>
                            <Flex flexDir={"column"} alignItems={"start"} fontSize={"10px"} gap={2} >
                                <ScrollLink to='aboutus'
                                    className='navLinkFooter footerHover'
                                    smooth={true}
                                    duration={500}
                                    offset={-25}>О нас</ScrollLink>
                                <ScrollLink to={"work"}
                                    className='navLinkFooter footerHover'
                                    smooth={true}
                                    duration={500}
                                    offset={-25}>Работы</ScrollLink>
                                <ScrollLink to={"specialequipment"}
                                    className='navLinkFooter footerHover'
                                    smooth={true}
                                    offset={50}
                                    duration={500}>
                                    Спецтехника
                                </ScrollLink>
                                <ScrollLink to={"services"}
                                    className='navLinkFooter footerHover'
                                    smooth={true}
                                    duration={500}
                                >Наши услуги</ScrollLink>
                                <ScrollLink to={"collectiv"}
                                    className='navLinkFooter footerHover'
                                    smooth={true}
                                    duration={500}
                                    offset={50}

                                >Коллектив</ScrollLink>
                            </Flex>
                        </Flex>

                    </Flex>
                    <Flex flexDir={"column"} gap={3}>
                        <Text pb={"8px"}>Наши контакты</Text>
                        <Flex flexDir={"column"} gap={2} className="" >
                            <Flex flexDir={"column"}>
                                <Link to={"tel:79528824439"} className="footerHover">+7 952 882-44-39</Link>
                                <Link to={"tel:79138548580"} className="footerHover">+7 913 854-85-80 </Link>
                            </Flex>
                            <Flex gap={3} flexDir={"column"}>
                                <Link to={"mailto:prestijstroy70@gmail.com"} className="footerHover">prestijstroy70@gmail.com</Link>
                                <Flex gap={3} >
                                    <Link to='t.me/+79528824439'>
                                        <Telegram className="footerHover" />
                                    </Link>
                                    <Link to='https://wa.me/79528824439'>
                                        <Whatsapp className="footerHover" />
                                    </Link>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex w={"full"} display={["flex", "flex", "flex", "none"]} alignItems={"center"} justifyContent={"center"}>
                <Flex>
                    <Image src={Logo} w={"250px"} h={"250px"} />
                </Flex>
            </Flex>
        </Flex>
    )
}


export default Footer