import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import SpecialEquipment from '../../../assets/img/main.jpg'
import Navsyalki from '../../../assets/img/navsyaki.jpeg'
import Logo from '../../../assets/img/stroykaLogo.png'
import './style.css'

function Content() {
    return (
        <Flex>
            <Flex className="bgImage" w={"full"} justifyContent={"center"} display={["none", "none", "none", "flex"]}>
                <Image w={"full"} h={"1050px"} src={SpecialEquipment}  />
                <Flex pos={"absolute"} maxW={"1500px"} w={"full"} flexDir={"column"} >
                    <Image src={Logo} w={"350px"} mt={"70px"} ml={"130px"} />
                    <Text w={"650px"} fontWeight={900} color={"white"} fontSize={"60px"} pt={"80px"} ml={"190px"} zIndex={100}>
                        Благустройство,
                        Асфальтирование,
                        Тратуарная Плитка
                        В Томске
                    </Text>
                </Flex>
            </Flex>
            <Flex w={"full"} className="bgImage" display={["flex", "flex", "flex", "none"]} justifyContent={"center"} alignItems={"center"}>
                <Image w={"full"} h={"800px"} src={SpecialEquipment} objectFit={"cover"} />
                <Flex pos={"absolute"} w={"full"} flexDir={"column"}  >
                    <Image src={Logo} w={"270px"} h={"270px"}  />
                    <Text textDecor={"underline"} textDecorationColor={"#FE9901"} textUnderlineOffset={"8px"} textAlign={"left"} fontWeight={900} color={"white"} fontSize={"30px"} pl={"40px"} pt={"150px"} zIndex={100}>
                        Благустройство,<br />
                        Асфальтирование,<br />
                        Тратуарная Плитка<br />
                        В Томске
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}


export default Content