import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import CollectivImage from "../../../assets/img/collectiv.jpeg"



function Collectiv() {
    return (
        <Flex w={"full"} pt={"50px"} id="collectiv" >
            <Flex w={"full"} justifyContent={"center"} pos={"relative"} display={["none", "none", "none", "flex"]}>
                <Image src={CollectivImage} w={"full"} pos={"relative"} h={"1050px"} />
                <Flex w={"full"} zIndex={50} bg={"rgba(0, 0, 0, 0.4)"} maxH={"1050px"} h={"full"} p={"190px"} pos={"absolute"} justifyContent={"center"}>

                    <Flex maxW={"1500px"} w={"full"} flexDir={"column"}  >
                        <Text w={"330px"} color={"white"} fontSize={"58px"} fontWeight={700} fontFamily={"Montserrat"} textDecor={"underline"} textDecorationColor={"#FE9901"} textUnderlineOffset={"10px"}>Коллектив </Text>
                        <Flex flexDir={"column"} mt={"240px"} color={"white"}>
                            <Flex flexDir={"column"} justifyContent={"space-between"} pos={"relative"} zIndex={"60"}>
                                <Text fontSize={"23px"} w={"975px"}>Слаженный коллектив состоит из профессиональных и грамотных специалистов: проектировщиков, прорабов, водителей и рабочих строительных бригад.
                                    Быстрый заказ, конструктивный диалог, слаженная работа. И результат - идеально ровная широкая дорога с прекрасным пейзажем.</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex display={["flex", "flex", "flex", "none"]}>
            <Image src={CollectivImage} w={"full"} pos={"relative"} h={"800px"} objectFit={"cover"} />
                <Flex w={"full"} zIndex={50} bg={"rgba(0, 0, 0, 0.4)"} h={"800px"} pos={"absolute"} justifyContent={"center"}>
                    <Flex maxW={"1500px"} w={"full"} p={"150px 0"}  >
                        <Flex flexDir={"column"} gap={2} color={"white"}>
                            <Flex flexDir={"column"} gap={"240px"} p={"15px 10px"} justifyContent={"space-between"} pos={"relative"} zIndex={"60"}>
                                <Text fontSize={"19px"} w={"115px"}  fontWeight={700} fontFamily={"Montserrat"} textDecor={"underline"} textDecorationColor={"#FE9901"} textUnderlineOffset={"5px"}>Коллектив</Text>
                                <Text fontSize={"14px"} >Слаженный коллектив состоит из профессиональных и грамотных специалистов: проектировщиков, прорабов, водителей и рабочих строительных бригад.
                                Быстрый заказ, конструктивный диалог, слаженная работа. И результат - идеально ровная широкая дорога с прекрасным пейзажем.</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}


export default Collectiv