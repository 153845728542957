import {createBrowserRouter} from "react-router-dom";
import Home from "../pages/Home";
import AuthLayout from "../Layouts/AuthLayout";
import OurServices from "../pages/Home/OurServices";

export const router = createBrowserRouter([
    {
        element: <AuthLayout />,
        children: [
            {
                path: '/',
                element: <Home/>
            },
           
        ]
    }
 
   
   

])